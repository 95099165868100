<script lang="ts" setup>
import type { InputAttributes } from "~ui/types/inputAttributes";
import type { TextInputTypes } from "~ui/types/textInputTypes";

interface Props extends InputAttributes {
  variant?: "default" | "underline" | "emphasised";
  type?: TextInputTypes;
  placeholder?: string | undefined;
  errorMessages?: string[];
  isRounded?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  autocomplete?: string;
  customClasses?: string;
  dataTestid?: string;
  buttonType?: "arrow-right";
}

const props = withDefaults(defineProps<Props>(), {
  variant: "default",
  type: "text",
  isRounded: false,
  errorMessages: () => [],
  placeholder: undefined,
  autocomplete: undefined,
  hideErrorMessage: false,
  customClasses: undefined,
  dataTestid: undefined,
  buttonType: undefined,
});

const value = defineModel<string | number>({ required: false });
const emit = defineEmits<{
  blur: [event: FocusEvent];
  handleButtonClick: [];
}>();
const hasError = computed<boolean>(() => !!props.errorMessages.length);
const hasValue = computed(() => {
  if (!value.value) {
    return false;
  }
  return value.value !== "";
});

const inputState = computed(() => {
  if (props.disabled) {
    return INPUT_STATE_DISABLED;
  }

  if (hasError.value) {
    return INPUT_STATE_ERROR;
  }

  if (hasValue.value) {
    return INPUT_STATE_VALID;
  }

  return INPUT_STATE_DEFAULT;
});

const hasButton = computed(() => !!props.buttonType);

const variantClasses = computed<string>(() => {
  return twMerge(
    cva("p-4 font-normal", {
      variants: {
        isRounded: {
          true: "rounded-4xl",
          false: "rounded-lg",
        },
        inputState: {
          [INPUT_STATE_ERROR]: "border-red-400",
          [INPUT_STATE_VALID]: "border-green-400",
          [INPUT_STATE_DEFAULT]: "border-primary-400",
          [INPUT_STATE_DISABLED]: "border-grey-400 text-grey-500",
        },
        variant: {
          underline:
            "w-full max-w-96 border-b-2 !border-b-grey-400 bg-transparent !rounded-none text-center text-white",
          default: "w-full border placeholder-grey-600 h-[50px]",
          emphasised: "w-full shadow-lg placeholder-grey-500",
        },
        hasButton: {
          true: "pr-14",
        },
      },
    })({
      isRounded: props.isRounded,
      inputState: inputState.value,
      variant: props.variant,
      hasButton: hasButton.value,
    }),
    props.customClasses,
  );
});

const buttonClasses = computed(() => {
  return twMerge(
    cva(
      "absolute right-2 top-1/2 transform -translate-y-1/2 bg-secondary-500 text-white rounded-full w-10 h-10 flex items-center justify-center",
      {
        variants: {
          disabled: {
            true: "bg-grey-300",
            false: "",
          },
        },
      },
    )({
      disabled: props.disabled,
    }),
  );
});

const elementId = useId();
</script>

<template>
  <div>
    <UIInputLabel
      v-if="!hideLabel"
      :for="elementId"
      :label="label"
      :required="required"
    />
    <div class="relative">
      <input
        :id="elementId"
        v-model="value"
        :aria-required="required"
        :autocomplete="autocomplete"
        :class="variantClasses"
        :data-testid="dataTestid"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :type="type"
        @blur="emit('blur', $event)"
      />
      <button
        v-if="buttonType"
        :aria-label="label"
        :disabled="disabled"
        :class="buttonClasses"
        @click="emit('handleButtonClick')"
      >
        <UIAssetIcon :path="buttonType" />
      </button>
    </div>
    <UIInputErrors
      v-if="!hideErrorMessage"
      :data-testid="`error-message-${elementId}`"
      :errorMessages="errorMessages"
    />
  </div>
</template>
